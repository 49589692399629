import { MotionImage } from "components/MotionImage";
import { Page } from "components/Page";
import TicketShop from "components/ticket-shop";
import React from "react";
import { Helmet } from "react-helmet";

export function TicketshopPage({
  scriptString,
  imageSrc,
  backgroundSrc,
  imageMaxWidth = "none",
  title,
  description,
}) {
  return (
    <Page>
      <Helmet title={title} />
      <div className="relative">
        <div className="relative flex items-center">
          {backgroundSrc && (
            <MotionImage
              className="relative inset-0 w-full h-full object-cover"
              src={backgroundSrc}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            />
          )}
          <MotionImage
            className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"
            src={imageSrc}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            style={{ maxWidth: imageMaxWidth, maxHeight: "80%" }}
          />
        </div>
        <div className="mt-12 mb-4 md:flex container mx-auto gap-16">
          <h1 className="text-4xl">{title}</h1>
          <div className="flex-1">{description}</div>
        </div>
        <div className="container mx-auto mt-24">
          {/* <h2 className="text-center mb-8">ALL EVENTS</h2> */}
          <TicketShop scriptString={scriptString} />
        </div>
      </div>
    </Page>
  );
}
